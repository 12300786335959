//货币和多语言纠正
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import schttp from '../../services/schttp'
import { getCookie, removeCookie } from '@shein/common-function'

const { lang, SUPPORT_LANGS, currency, currencies = {} } = gbCommonInfo

const currencyManager = UserInfoManager.get({ key: 'currency' })
const langManager = UserInfoManager.get({ key: 'language' })

let nowCurrencies = []

try {
  nowCurrencies = Object.keys(currencies || {})
} catch(e){ 
  console.log('currency error', e)
  nowCurrencies = []
}

let currencyDisMatch = false
let langDisMatch = false

//如果货币不匹配 
if( currency != currencyManager ){
  gbCommonInfo.currency = currencyManager
  currencyDisMatch = true
}

//如果多语言不匹配 
if( langManager != lang ){
  gbCommonInfo.lang = langManager
  langDisMatch = false
}


if( langDisMatch || currencyDisMatch ){
  let data = {
    SUPPORT_LANGS, nowCurrencies,
    lang, currency, currencyManager, langManager
  }

  langDisMatch && (data.langDisMatch = 1)
  currencyDisMatch && (data.currencyDisMatch = 1)
  nowCurrencies.includes(currency) && (data.currencyInNowCurrencies = 1)

  schttp({
    method: 'POST',
    url: '/api/common/infoDismatchLog/post',
    data,
  })
}

// /******** 以下是对老用户的cookie 处理 */
//oest处理老用户的在线cookie

// function encodeToUnicode(str) {
//   return str.split('').map(char => {
//     let code = char.charCodeAt(0).toString(16);
//     return "\\u" + code.padStart(4, '0'); // 使用 \uXXXX 格式
//   }).join('');
// }

//该编码值由以上encodeToUnicode函数生成
const oldStr = '\\u0063\\u006f\\u006f\\u006b\\u0069\\u0065\\u0049\\u0064'

function decodeFromUnicode(unicodeStr) {
  let str = ''
  try {
    str = unicodeStr.replace(/\\u([\da-f]{4})/gi, (match, code) => {
      return String.fromCharCode(parseInt(code, 16))
    })
  } catch (e){
    console.error('decodeFromUnicode error', e)
  }
  return str
}

const keyStr = decodeFromUnicode(oldStr)
// eslint-disable-next-line 
if( keyStr && getCookie(keyStr) ){
  schttp({
    method: 'POST',
    url: '/api/common/infoOc/post',
  }).then(res => {
    if( res?.code == 0 && res.info?.make == 1 ){
      removeCookie({ key: keyStr })
    }
  })
}
