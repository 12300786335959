import { removeLocalStorage } from '@shein/common-function'

const { SiteUID } = gbCommonInfo

const CACHE_MAP = {
  have_show: 'hideRegisterPop',
  hideCoupon: 'couponPkgPopHide',
  hideCouponId_time: 'couponPkgPopHide_id_time',
  hideCoupon_type: 'couponPkgPopHide_type'
}

const site = SiteUID.replace('rw', '')

CACHE_MAP[`no_pop_up_${site}`] = `ret_dir_cache_${site}`

try {
  for (let key in CACHE_MAP) {
    const cache = localStorage.getItem(key)
    if (cache) {
      removeLocalStorage(key)
      localStorage.setItem(CACHE_MAP[key], cache)
      console.log('旧缓存已经删除, key:', key)
    }
  }
} catch (error) {
  console.log('error', error)
}
